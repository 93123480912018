import React from "react";
import Sidebar from "../common/Sidebar";
import Navbar from "../common/Navbar";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { navBarAnim } from "../../Animation/animation";
import "react-toastify/dist/ReactToastify.css";

const Layouts = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <section className="flex">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={navBarAnim}
            initial="hidden"
            animate="show"
            exit="exit"
            layout
          >
            <Sidebar />
          </motion.div>
        )}
      </AnimatePresence>
      <section className="flex-1 min-h-screen flex flex-col w-[calc(100%-225px)]">
        <Navbar open={setIsOpen} isOpen={isOpen} />
        <main className="p-4 bg-gray-100 flex-1">{props.children}</main>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </section>
    </section>
  );
};

export default Layouts;

/*   return (
    <section>
      {isOpen && <Sidebar />}
      <div className="flex">
        {isOpen && <div className="w-[225px] h-min-screen"></div>}
        <section className="flex-1 min-h-screen flex flex-col">
          <Navbar open={setIsOpen} isOpen={isOpen} />
          <main className="p-4 bg-gray-100 flex-1">{props.children}</main>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </section>
      </div>
    </section>
  );
};

export default Layouts; */
