import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/common/Button";
import { useLocation, useNavigate } from "react-router-dom";

const EditMetaDetail = () => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.adminToken);
  const navigate = useNavigate();
  const { metaDetailId } = useParams();
  const [fields, setFields] = useState({
    id: "",
    metaDesc: "",
    metaTitle: "",
    metaKeyword: "",
    routeName: "",
  });

  useEffect(() => {
    const getMetaDetailById = async () => {
      const resp = await axios.get(
        `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/metaDetail/${metaDetailId}`,
        { headers: { Authorization: token } }
      );
      if (resp.data.message === "Success") {
        const data = resp.data.result;
        setFields({
          id: data._id,
          metaDesc: data.metaDesc,
          metaTitle: data.metaTitle,
          metaKeyword: data.metaKeyword,
          routeName: data.routeName,
        });
      }
    };
    getMetaDetailById();
  }, [metaDetailId, token]);

  const handleCancel = () => {
    navigate("/metaDetail");
  };

  useEffect(() => {
    localStorage.setItem("location", location.pathname);
  });

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id: fields?.id,
      metaDesc: fields?.metaDesc,
      metaTitle: fields?.metaTitle,
      metaKeyword: fields?.metaKeyword,
      routeName: fields?.routeName,
    };
    const resp = await axios.put(
      `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/metaDetail/`,
      data,
      { headers: { Authorization: token } }
    );
    if (resp.data.message === "Updated") navigate("/metaDetail");
    else toast.error("Something went wrong");
  };

  const handleOnChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (!fields) {
    return <h1 className="text-4xl text-primary">Loading meta details...</h1>;
  }

  return (
    <div className="px-8 py-4">
      <h1 className="text-2xl text-primary">Edit Meta Detail</h1>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="grid grid-cols-2 pb-8 pt-4 gap-y-4 gap-x-12"
      >
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="routeName">
            Route Name<small>*</small>
          </label>
          <input
            type="text"
            name="routeName"
            id="routeName"
            maxLength={100}
            onChange={handleOnChange}
            value={fields.routeName}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="metaDesc">
            Meta Description<small>*</small>
          </label>
          <input
            type="text"
            name="metaDesc"
            id="metaDesc"
            maxLength={300}
            onChange={handleOnChange}
            value={fields?.metaDesc}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="metaTitle">
            Meta Title<small>*</small>
          </label>
          <input
            type="text"
            name="metaTitle"
            id="metaTitle"
            maxLength={100}
            onChange={handleOnChange}
            value={fields.metaTitle}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="metaKeyword">
            Meta Keyword (seperated by comma)<small>*</small>
          </label>
          <input
            type="text"
            name="metaKeyword"
            id="metaKeyword"
            maxLength={500}
            onChange={handleOnChange}
            value={fields?.metaKeyword}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex gap-4">
          <Button
            text="Cancel"
            type="submit"
            className={`text-white px-10 py-2 bg-amber-500 border-amber-500`}
            handler={handleCancel}
          />
          <Button
            text="Update"
            type="submit"
            className={`text-white px-10 py-2 border-primary cursor-pointer`}
          />
        </div>
      </form>
    </div>
  );
};

export default EditMetaDetail;
