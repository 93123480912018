import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "../common/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getAllVariants } from "../../store/slice/activitySlice";

const AddVariant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.auth.adminToken);
  const activities = useSelector((state) => state.activity.activities);
  const combos = useSelector((state) => state.activity.combos);

  const [fields, setFields] = useState({
    variants: [],
    price: "",
    variantType: "",
    noOfPerson: "",
    slotPerDay: "",
  });

  const handleCancel = () => {
    navigate("/variant");
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("location", location.pathname);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { variantType, noOfPerson, price, slotPerDay } = fields;
    const data = {
      id: fields.variants,
      variantType,
      noOfPerson,
      price,
      slotPerDay,
    };

    const resp = await axios.post(
      `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/variant`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (resp.data.message === "Created") {
      dispatch(getAllVariants())
        .then(() => navigate("/variant"))
        .then(() => toast.success("Added Successfully", { autoClose: 1500 }));
    } else {
      toast.error("Something went wrong", { autoClose: 1500 });
    }
  };

  const handleOnChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className="px-8 py-4">
      <h1 className="text-2xl text-primary">Add Activtiy</h1>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="grid grid-cols-2 pb-8 pt-4 gap-y-4 gap-x-12"
      >
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="activities">
            Activities & Combos Included:
          </label>
          <Select
            labelId="demo-multiple-name-label"
            id="variants"
            name="variants"
            value={fields.variants}
            onChange={handleOnChange}
            className="bg-white"
            required
          >
            <p className="text-xl border-l-4 pl-3 outline-none border-l-amber-600 font-semibold">
              Combos
            </p>
            {combos?.map((act) => (
              <MenuItem key={act._id} value={act._id}>
                {act.name}
              </MenuItem>
            ))}
            <p className="text-xl border-l-4 pl-3 outline-none border-l-amber-600 font-semibold">
              Activities
            </p>
            {activities?.map((act) => (
              <MenuItem key={act._id} value={act._id}>
                {act.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="variantType">
            variant Type:
          </label>
          <input
            type="text"
            name="variantType"
            id="variantType"
            pattern="^[^-\s][a-zA-Z0-9 \s]*$"
            onChange={handleOnChange}
            value={fields.variantType}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="noOfPerson">
            Number Of Person:
          </label>
          <input
            min={1}
            type="number"
            name="noOfPerson"
            id="noOfPerson"
            onChange={handleOnChange}
            value={fields?.noOfPerson}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="price">
            Price:
          </label>
          <input
            min={1}
            type="number"
            name="price"
            id="price"
            onChange={handleOnChange}
            value={fields?.price}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="slotPerDay">
            Slot Per Day:
          </label>
          <input
            min={1}
            type="number"
            name="slotPerDay"
            id="slotPerDay"
            onChange={handleOnChange}
            value={fields?.slotPerDay}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1"></div>
        <div className="flex gap-4 mt-8">
          <Button
            text="Cancel"
            type="submit"
            className={`text-white px-10 py-2 bg-amber-500 border-amber-500`}
            handler={handleCancel}
          />
          <Button
            text="Submit"
            type="submit"
            className="text-white px-10 py-2 border-primary"
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default AddVariant;
