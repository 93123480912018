import axios from "axios";

const BASE_URL = process.env.REACT_APP_ADMIN_URL;
const DISCOUNT_URL = "admin/private/api/discount/";

const getAllDiscounts = async (token) => {
  const resp = await axios.get(`${BASE_URL}${DISCOUNT_URL}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const getDiscountById = async (id, data, token) => {
  const resp = await axios.get(`${BASE_URL}${DISCOUNT_URL}/${id}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const addDiscount = async (data, token) => {
  const resp = await axios.post(`${BASE_URL}${DISCOUNT_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const updateDiscount = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${DISCOUNT_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const deleteDiscount = async (data, token) => {
  const resp = await axios.delete(`${BASE_URL}${DISCOUNT_URL}`, {
    headers: {
      Authorization: token,
    },
    data,
  });
  return resp.data;
};

const DiscountServices = {
  getAllDiscounts,
  getDiscountById,
  addDiscount,
  updateDiscount,
  deleteDiscount,
};

export default DiscountServices;
