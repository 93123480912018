import { Link, NavLink, useLocation } from "react-router-dom";
import thrillLogo from "../../assets/images/logoColor.webp";
import { motion, LayoutGroup } from "framer-motion";
import { activePage } from "../../data/pageActive";

const Sidebar = () => {
  const location = useLocation();

  return (
    <motion.aside className="h-full relative">
      <div className="flex justify-center py-4">
        <NavLink to="/">
          <img src={thrillLogo} alt="thrill Logo" />
        </NavLink>
      </div>
      <nav className="sticky top-0">
        <motion.ul className="pt-6">
          <LayoutGroup>
            {activePage.map((e, index) => (
              <Link to={e.url} key={e.id}>
                <motion.li
                  className={
                    location.pathname.startsWith(e.url)
                      ? "cursor-pointer py-3 text-primary w-full"
                      : "ml-1 cursor-pointer py-3 text-gray-500 w-full"
                  }
                >
                  <motion.div className="flex flex-row-reverse justify-end h-8 w-full">
                    <motion.span className="flex items-center gap-4 pl-4 relative">
                      {e.icon}
                      {e.title}
                    </motion.span>
                    {location.pathname.startsWith(e.url) ? (
                      <motion.div
                        className="min-h-full w-1 rounded-r-lg bg-primary"
                        layoutId="min-h-full w-1 rounded-r-lg bg-primary"
                      />
                    ) : null}
                  </motion.div>
                </motion.li>
              </Link>
            ))}
          </LayoutGroup>
        </motion.ul>
      </nav>
    </motion.aside>
  );
};

export default Sidebar;
