import * as yup from "yup";

const nameRegExp =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm;
const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email")
    .max(255)
    .required("Field is required"),
  password: yup
    .string()
    .matches(nameRegExp)
    .min(8, "Password should be minimum 8 characters")
    .required("Field is required"),
});

export const schemas = {
  loginSchema,
};
