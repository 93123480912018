import axios from "axios";

const BASE_URL = process.env.REACT_APP_ADMIN_URL;
const MEALS_URL = "admin/private/api/meal";

const getAllMeals = async (token) => {
  const resp = await axios.get(`${BASE_URL}${MEALS_URL}/all`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const getMealById = async (data, token) => {
  const resp = await axios.get(`${BASE_URL}${MEALS_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const addMeal = async (data, token) => {
  const resp = await axios.post(`${BASE_URL}${MEALS_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const updateMeal = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${MEALS_URL}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const deleteMeal = async (data, token) => {
  const resp = await axios.delete(`${BASE_URL}${MEALS_URL}`, {
    headers: {
      Authorization: token,
    },
    data,
  });
  return resp.data;
};

const mealServices = {
  getAllMeals,
  getMealById,
  addMeal,
  updateMeal,
  deleteMeal,
};

export default mealServices;
