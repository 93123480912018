import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authServices from "../service/authService";

const initialAuthState = {
  isLoggedIn: false,
  status: "",
  message: "",
  adminToken: null,
  email: "",
  userData: [],
};

export const loginAdmin = createAsyncThunk(
  "auth/admin",
  async (data, thunkAPI) => {
    try {
      return await authServices.loginAdmin(data);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "auth/getUsers",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await authServices.getAllUsers(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const getAdmin = createAsyncThunk(
  "auth/getAdmin",
  async (token, thunkAPI) => {
    try {
      return await authServices.getAdmin(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    logout: (state) => {
      Object.assign(state, initialAuthState);
      localStorage.removeItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.isLoggedIn = false;
        state.message = "pending";
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.message = action.payload.message;
        state.adminToken = action.payload.result.accessToken;
        state.email = action.payload.result.email;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.message = action.payload.message;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.message = "pending";
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.userData = action.payload.result;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.message = action.payload;
      })
      .addCase(getAdmin.pending, (state) => {
        state.message = "pending";
        state.isLoggedIn = false;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        if (!action.payload.result.email) state.isLoggedIn = false;
        else {
          state.isLoggedIn = true;
          state.message = action.payload.message;
          state.email = action.payload.result.email;
          state.adminToken = action.payload.result.accesstoken;
        }
      })
      .addCase(getAdmin.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.message = action.payload;
      });
  },
});

export const { authActions, logout } = authSlice.actions;

export default authSlice.reducer;
