import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import paymentService from "../service/paymentService";

const initialAuthState = {
  message: "",
  allPayments: [],
};

export const paymentHistory = createAsyncThunk(
  "payment/paymentHistory",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem(
        "bf215bc5-252d-4e76-9b06-754f55268dd2"
      );
      return await paymentService.paymentHistory(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialAuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(paymentHistory.pending, (state) => {
        state.message = "pending";
      })
      .addCase(paymentHistory.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.allPayments = action.payload;
      })
      .addCase(paymentHistory.rejected, (state, action) => {
        state.message = action.payload.message;
      });
  },
});

export const paymentActions = paymentSlice.actions;

export default paymentSlice.reducer;
