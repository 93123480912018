import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/common/Button";
import { getAllVariants, updateVariant } from "../../store/slice/activitySlice";

const EditVariant = () => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.adminToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { variantId } = useParams();
  const [fields, setFields] = useState({
    id: "",
    activityId: "",
    name: "",
    variantType: "",
    price: "",
    noOfPerson: "",
    slotPerDay: "",
  });

  useEffect(() => {
    const getVariantById = async () => {
      const resp = await axios.get(
        `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/variant/${variantId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (resp.data.message === "Success") {
        const data = resp.data.result;
        setFields({
          id: data._id,
          activityId: data.activityId,
          name: data.name,
          price: data.price,
          variantType: data.variantType,
          noOfPerson: data.noOfPerson,
          slotPerDay: data.slotPerDay,
        });
      }
    };
    getVariantById();
  }, [variantId, token]);

  const handleCancel = () => {
    navigate("/variant");
  };

  useEffect(() => {
    localStorage.setItem("location", location.pathname);
  });

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: fields.id,
      activityId: fields.activityId,
      variantType: fields.variantType,
      price: fields.price,
      noOfPerson: fields.noOfPerson,
      slotPerDay: fields.slotPerDay,
    };
    dispatch(updateVariant(data))
      .then(() => dispatch(getAllVariants()))
      .then(() => navigate("/variant"))
      .then(() => toast("Updated Successfully", { autoClose: 1000 }));
  };

  const handleOnChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (!fields) {
    return <h1 className="text-4xl text-primary">Loading Activity...</h1>;
  }

  return (
    <div className="px-8 py-4">
      <h1 className="text-2xl text-primary">Edit Activity</h1>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="grid grid-cols-2 pb-8 pt-4 gap-y-4 gap-x-12"
      >
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="name">
            Activity Name:
          </label>
          <div className="bg-white p-2 rounded border border-1 border-solid">
            {fields.name}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="variantType">
            variant Type:
          </label>
          <input
            type="text"
            name="variantType"
            id="variantType"
            pattern="^[^-\s][a-zA-Z0-9 \s]*$"
            onChange={handleOnChange}
            required
            value={fields.variantType}
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="noOfPerson">
            Number of Person:
          </label>
          <input
            type="number"
            min={1}
            name="noOfPerson"
            id="noOfPerson"
            onChange={handleOnChange}
            value={fields.noOfPerson}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="price">
            Price:
          </label>
          <input
            type="number"
            min={1}
            name="price"
            id="price"
            onChange={handleOnChange}
            value={fields.price}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="slotPerDay">
            Slot Per Day:
          </label>
          <input
            type="number"
            min={1}
            name="slotPerDay"
            id="slotPerDay"
            onChange={handleOnChange}
            value={fields.slotPerDay}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1"></div>
        <div className="flex gap-4">
          <Button
            text="Cancel"
            type="submit"
            className={`text-white px-10 py-2 bg-amber-500 border-amber-500`}
            handler={handleCancel}
          />
          <Button
            text="Update"
            type="submit"
            className={`text-white px-10 py-2 border-primary cursor-pointer`}
          />
        </div>
      </form>
    </div>
  );
};

export default EditVariant;
