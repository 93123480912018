import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Switch } from "@mui/material";
import { toast } from "react-toastify";
import UploadImage from "../common/UploadImage";
import placeholder from "../../assets/images/placeholder.png";
import Button from "../common/Button";

const AddBlog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.adminToken);
  const [fields, setFields] = useState({
    heading: "",
    category: "",
    location: "",
    status: true,
    description: "",
    imageUrl: "",
    order: "",
  });

  const handleCancel = () => {
    navigate("/blog");
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("location", location.pathname);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      heading,
      imageUrl,
      category,
      description,
      location,
      status,
      order,
    } = fields;
    const data = {
      heading,
      imageUrl,
      category,
      description,
      location,
      status,
      order,
    };
    const resp = await axios.post(
      `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/blog`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (resp.data.message === "Created") {
      navigate("/blog");
      toast.success("Added Successfully", { autoClose: 1500 });
    } else {
      toast.error("Something went wrong", { autoClose: 1500 });
    }
  };

  const handleImageChange = async (e) => {
    const data = new FormData();
    data.append("image", e.target.files[0]);
    const resp = await toast.promise(
      axios.post(
        `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/blog/single`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      ),
      {
        pending: "Uploading Image",
        success: "Image uploaded successfully",
        error: "Something went wrong",
      }
    );

    if (resp.data.message === "Success") {
      setFields((prevFields) => ({ ...prevFields, imageUrl: resp.data.url }));
    }
  };

  const handleOnChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div className="px-8 py-4">
      <h1 className="text-2xl text-primary">Add Blog</h1>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="grid grid-cols-2 pb-8 pt-4 gap-y-4 gap-x-12"
      >
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="heading">
            Heading<small>*</small>
          </label>
          <input
            type="text"
            name="heading"
            id="heading"
            pattern="^[^-\s][a-zA-Z0-9() \s]*$"
            onChange={handleOnChange}
            value={fields.heading}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div>
          <label className="text-gray-500 block mb-1">
            Status<small>*</small>
          </label>
          <div className="flex gap-1 items-center bg-white border border-gray-200 px-2 rounded-sm">
            <p className={!fields.status ? "text-red-500" : "text-gray-500"}>
              Inactive
            </p>
            <Switch
              checked={fields.status}
              color="warning"
              onChange={handleOnChange}
              inputProps={{ "aria-label": "controlled" }}
              name="status"
            />
            <p className={fields.status ? "text-green-500" : "text-gray-500"}>
              Active
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="location">
            Location<small>*</small>
          </label>
          <input
            type="text"
            name="location"
            id="location"
            pattern="^[^-\s][a-zA-Z \s]*$"
            onChange={handleOnChange}
            value={fields.location}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="category">
            Category<small>*</small>
          </label>
          <input
            type="text"
            name="category"
            id="category"
            pattern="^[^-\s][a-zA-Z \s]*$"
            onChange={handleOnChange}
            value={fields.category}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <fieldset>
          <label htmlFor="description" className="block mb-1 text-gray-500">
            Description<small>*</small>
          </label>
          <textarea
            id="description"
            name="description"
            value={fields.description}
            onChange={handleOnChange}
            rows={5}
            required
            className="border p-2 w-full outline-none rounded"
          ></textarea>
        </fieldset>

        <div className="flex gap-4">
          <div className="w-40 rounded-lg mt-8">
            {fields?.imageUrl ? (
              <img src={fields.imageUrl} alt="blog" />
            ) : (
              <img src={placeholder} alt={"add blog"} />
            )}
          </div>
          <UploadImage onChange={handleImageChange} title="Blog Image" />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="order">
            Order<small>*</small>
          </label>
          <input
            min={1}
            type="number"
            name="order"
            id="order"
            onChange={handleOnChange}
            value={fields?.order}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1"></div>
        <div className="flex gap-4 mt-4">
          <Button
            text="Cancel"
            type="submit"
            className={`text-white px-10 py-2 bg-amber-500 border-amber-500`}
            handler={handleCancel}
          />
          <Button
            text="Submit"
            type="submit"
            disabled={fields.imageUrl.length <= 0}
            className={`text-white px-10 py-2 border-primary ${
              fields.imageUrl.length > 0
                ? "cursor-pointer"
                : "cursor-not-allowed"
            }`}
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default AddBlog;
