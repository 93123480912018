import axios from "axios";

const BASE_URL = process.env.REACT_APP_ADMIN_URL;
const ACTIVITY = "admin/private/v2/api/activity/";
const COMBO = "admin/private/v2/api/package/";
const VARIANT = "admin/private/v2/api/variant/";

const getAllActivities = async (token) => {
  const resp = await axios.get(`${BASE_URL}${ACTIVITY}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const getAllMetaCombos = async (token) => {
  const resp = await axios.get(`${BASE_URL}${COMBO}`, {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};

const getAllVariants = async (token) => {
  const resp = await axios.get(`${BASE_URL}${VARIANT}`, {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};

const createActivity = async (data, token) => {
  const resp = await axios.post(`${BASE_URL}${ACTIVITY}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const updateActivity = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${ACTIVITY}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const updateVariant = async (data, token) => {
  const resp = await axios.put(`${BASE_URL}${VARIANT}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const SINGLE_UPLOAD = "admin/private/api/activity/single";

const mainImageUpload = async (data, token) => {
  const resp = await axios.post(`${BASE_URL}${SINGLE_UPLOAD}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const MULTIPLE_UPLOAD = "admin/private/api/activity/multiple";

const multipleUpload = async (data, token) => {
  const resp = await axios.post(`${BASE_URL}${MULTIPLE_UPLOAD}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};
const activityService = {
  updateVariant,
  getAllVariants,
  createActivity,
  multipleUpload,
  updateActivity,
  mainImageUpload,
  getAllActivities,
  getAllMetaCombos,
};

export default activityService;
