import { motion } from "framer-motion";
/* import { BiUserCircle } from "react-icons/bi"; */
import { useSelector, useDispatch } from "react-redux";
import { GoSignOut } from "react-icons/go";
import hamburger from "../../assets/menu.svg";
import { navbarIconAnim } from "../../Animation/animation";
import { activePage } from "../../data/pageActive";
import { useNavigate, useLocation } from "react-router-dom";
import { logout as logoutDispatch } from "../../store/slice/authSlice";

const Navbar = (props) => {
  const { email } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    const resp = window.confirm("Do You want to Log Out?");
    if (resp) {
      dispatch(logoutDispatch());
      navigate("/");
    }
  };

  const location = useLocation();
  const page = activePage.find((page) =>
    location.pathname.startsWith(page.url)
  );

  return (
    <div
      className={`flex justify-between bg-primary p-6 text-white ${props.className}`}
    >
      <div className="flex items-center gap-4">
        <motion.span
          className="flex items-center hover:cursor-pointer"
          onClick={() => props.open(!props.isOpen)}
          variants={navbarIconAnim}
          animate={props.isOpen ? "show" : "hidden"}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <img src={hamburger} alt="hamburger" />
        </motion.span>
        <span className="font-medium">{page?.title}</span>
      </div>
      <ul className="flex gap-4">
        <li className="flex gap-6 items-center">
          {/* <BiUserCircle size={"1.5em"} /> */}
          <h1>{email}</h1>
          <GoSignOut
            size={"2.7em"}
            onClick={logout}
            className="hover:bg-red-400 p-1 hover:rounded cursor-pointer"
            title="LogOut"
          />
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
