const UploadImage = ({ onChange, title, onboxText, htmlFor }) => {
  return (
    <fieldset className="flex-1">
      <label htmlFor={htmlFor} className="block mb-1 text-gray-500">
        {title}
        <small>*</small>
      </label>
      <div
        className="h-32 bg-white border-4 border-gray-500 border-dashed rounded relative"
        id="image"
      >
        <p className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm font-medium">
          Click here to upload {onboxText ? onboxText : "image"}
        </p>
        {onboxText ? (
          <input
            type="file"
            accept=".mp4, .mkv"
            className="opacity-0 w-full h-full absolute z-20 cursor-pointer"
            onChange={onChange}
            name="image"
          />
        ) : (
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .webp"
            className="opacity-0 w-full h-full absolute z-20 cursor-pointer"
            onChange={onChange}
            name="image"
          />
        )}
      </div>
    </fieldset>
  );
};

export default UploadImage;
