import axios from "axios";

const BASE_URL = process.env.REACT_APP_ADMIN_URL;
const PAYMENT_URL = "admin/private/api/bookings/paymentHistory";

const paymentHistory = async (token) => {
  const resp = await axios.get(`${BASE_URL}${PAYMENT_URL}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const paymentService = {
  paymentHistory,
};

export default paymentService;
