import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/common/Button";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

const EditEmployee = () => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.adminToken);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const [fields, setFields] = useState({
    id: "",
    firstName: "",
    status: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    role: "",
  });

  useEffect(() => {
    const getEmployeeById = async () => {
      const resp = await axios.get(
        `${process.env.REACT_APP_ADMIN_URL}admin/private/api/auth/employee/${employeeId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (resp.data.message === "Success") {
        const data = resp.data.result;
        setFields({
          id: data._id,
          firstName: data.firstName,
          status: data.status,
          lastName: data.lastName,
          email: data.email,
          password: "",
          confirmPassword: "",
          phoneNumber: data.phoneNumber,
          role: data.role,
        });
      }
    };
    getEmployeeById();
  }, [employeeId, token]);

  const handleCancel = () => {
    navigate("/employees");
  };

  function handleOnClick() {
    setVisible(!visible);
  }
  function handleOnClick1() {
    setVisible1(!visible1);
  }

  useEffect(() => {
    localStorage.setItem("location", location.pathname);
  });

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: fields.id,
      firstName: fields.firstName,
      status: fields.status,
      lastName: fields.lastName,
      email: fields.email,
      password: fields.password,
      confirmPassword: fields.confirmPassword,
      phoneNumber: fields.phoneNumber,
      role: fields.role,
    };
    updateEmployee(data);
  };

  const updateEmployee = async (data) => {
    const resp = await axios.put(
      `${process.env.REACT_APP_ADMIN_URL}admin/private/api/auth/employee/`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (resp.data.message === "Updated") {
      navigate("/employees");
      toast.success("Updated Successfully", { autoClose: 1500 });
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleOnChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (!fields) {
    return <h1 className="text-4xl text-primary">Loading Activity...</h1>;
  }

  return (
    <div className="px-8 py-4">
      <h1 className="text-2xl text-primary">Edit Combo</h1>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="grid grid-cols-2 pb-8 pt-4 gap-y-4 gap-x-12"
      >
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="firstName">
            First Name<small>*</small>
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            pattern="^[^-\s][a-zA-Z0-9() \s]*$"
            onChange={handleOnChange}
            value={fields.firstName}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="lastName">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            pattern="^[^-\s][a-zA-Z0-9() \s]*$"
            onChange={handleOnChange}
            value={fields.lastName}
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div>
          <label className="text-gray-500 block mb-1">
            Status<small>*</small>
          </label>
          <div className="flex gap-1 items-center bg-white border border-gray-200 px-2 rounded-sm">
            <p className={!fields.status ? "text-red-500" : "text-gray-500"}>
              Inactive
            </p>
            <Switch
              checked={fields.status}
              color="warning"
              onChange={handleOnChange}
              inputProps={{ "aria-label": "controlled" }}
              name="status"
            />
            <p className={fields.status ? "text-green-500" : "text-gray-500"}>
              Active
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="email">
            Email<small>*</small>
          </label>
          <input
            min={1}
            type="email"
            name="email"
            id="email"
            onChange={handleOnChange}
            value={fields.email}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="role">
            Role<small>*</small>
          </label>
          <input
            type="text"
            name="role"
            id="role"
            onChange={handleOnChange}
            value={fields?.role}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="phoneNumber">
            Phone Number<small>*</small>
          </label>
          <input
            maxLength={10}
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            onChange={handleOnChange}
            value={fields?.phoneNumber}
            required
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="password">
            <div className="flex justify-start items-center">
              <span className="inline-block">
                Password<small>* (minimum 8 characters)</small>
              </span>
              <span className="inline-block">
                {visible ? (
                  <AiFillEye
                    onClick={handleOnClick}
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <AiFillEyeInvisible
                    onClick={handleOnClick}
                    className="cursor-pointer"
                    size={20}
                  />
                )}
              </span>
            </div>
          </label>
          <input
            min={8}
            type={visible ? "text" : "password"}
            name="password"
            id="password"
            onChange={handleOnChange}
            value={fields.password}
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-gray-500" htmlFor="confirmPassword">
            <div className="flex justify-start items-center">
              <span className="inline-block">
                Confirm Password<small>* (minimum 8 characters)</small>
              </span>
              <span className="inline-block">
                {visible1 ? (
                  <AiFillEye
                    onClick={handleOnClick1}
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <AiFillEyeInvisible
                    onClick={handleOnClick1}
                    className="cursor-pointer"
                    size={20}
                  />
                )}
              </span>
            </div>
          </label>
          <input
            min={8}
            type={visible1 ? "text" : "password"}
            name="confirmPassword"
            id="confirmPassword"
            onChange={handleOnChange}
            value={fields?.confirmPassword}
            className="border p-2 w-full outline-none rounded"
          />
        </div>
        <div className="flex gap-4">
          <Button
            text="Cancel"
            type="submit"
            className={`text-white px-10 py-2 bg-amber-500 border-amber-500`}
            handler={handleCancel}
          />
          <Button
            text="Update"
            type="submit"
            disabled={fields.password !== fields.confirmPassword}
            className={`text-white px-10 py-2 border-primary ${
              fields.password === fields.confirmPassword
                ? "cursor-pointer"
                : "cursor-not-allowed"
            }`}
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default EditEmployee;
