import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import activityService from "../service/activityService";

const initialAuthState = {
  message: "",
  status: "",
  activity: {
    main_image_url: "",
    multiple_images: [],
  },
  activities: [],
  variants: [],
  combos: [],
};

export const getAllActivities = createAsyncThunk(
  "activities/getAll",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await activityService.getAllActivities(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const getAllMetaCombos = createAsyncThunk(
  "combos/getAll",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await activityService.getAllMetaCombos(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const getAllVariants = createAsyncThunk(
  "variants/getAll",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await activityService.getAllVariants(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const createActivity = createAsyncThunk(
  "activity/createActivity",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem(
        "bf215bc5-252d-4e76-9b06-754f55268dd2"
      );
      return await activityService.createActivity(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const mainImageUpload = createAsyncThunk(
  "activity/mainImageUpload",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem(
        "bf215bc5-252d-4e76-9b06-754f55268dd2"
      );
      return await activityService.mainImageUpload(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const updateActivity = createAsyncThunk(
  "activity/update",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await activityService.updateActivity(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const updateVariant = createAsyncThunk(
  "variants/update",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await activityService.updateVariant(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const multipleUpload = createAsyncThunk(
  "activity/multipleUpload",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem(
        "bf215bc5-252d-4e76-9b06-754f55268dd2"
      );
      return await activityService.multipleUpload(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

const activitySlice = createSlice({
  name: "activity",
  initialState: initialAuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivities.pending, (state) => {
        state.status = "pending";
        state.message = "Loading Activities";
      })
      .addCase(getAllActivities.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Activities fetched successfully";
        state.activities = action.payload.result;
      })
      .addCase(getAllActivities.rejected, (state, action) => {
        state.status = "rejected";
        state.activities = [];
        state.message = `Something went wrong while fetching activities ${action.payload.message}`;
      })
      .addCase(getAllMetaCombos.pending, (state) => {
        state.status = "pending";
        state.message = "Loading Combos";
      })
      .addCase(getAllMetaCombos.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Combos fetched successfully";
        state.combos = action.payload.result;
      })
      .addCase(getAllMetaCombos.rejected, (state, action) => {
        state.status = "rejected";
        state.combos = [];
        state.message = `Something went wrong while fetching activities ${action.payload.message}`;
      })
      .addCase(getAllVariants.pending, (state) => {
        state.status = "pending";
        state.message = "Loading Activities";
      })
      .addCase(getAllVariants.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Activities fetched successfully";
        state.variants = action.payload.result;
      })
      .addCase(getAllVariants.rejected, (state, action) => {
        state.status = "rejected";
        state.variants = [];
        state.message = `Something went wrong while fetching activities ${action.payload.message}`;
      })
      .addCase(createActivity.pending, (state) => {
        state.message = "pending";
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(createActivity.rejected, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(mainImageUpload.pending, (state) => {
        state.message = "pending";
      })
      .addCase(mainImageUpload.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.activity.main_image_url = action.payload.url;
      })
      .addCase(mainImageUpload.rejected, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(multipleUpload.pending, (state) => {
        state.message = "pending";
      })
      .addCase(multipleUpload.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.activity.multiple_images = action.payload.urls;
      })
      .addCase(multipleUpload.rejected, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(updateActivity.pending, (state) => {
        state.message = "pending";
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(updateActivity.rejected, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(updateVariant.pending, (state) => {
        state.message = "pending";
      })
      .addCase(updateVariant.fulfilled, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(updateVariant.rejected, (state, action) => {
        state.message = action.payload.message;
      });
  },
});

export const activityAction = activitySlice.actions;

export default activitySlice.reducer;
