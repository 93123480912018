import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DiscountServices from "../service/discountService";

const initialDiscountState = {
  discounts: [],
  status: "",
  message: "",
};

export const getAllDiscounts = createAsyncThunk(
  "discounts/getAll",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await DiscountServices.getAllDiscounts(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const getDiscountById = createAsyncThunk(
  "discount/getById",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await DiscountServices.getDiscountById(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const addDiscount = createAsyncThunk(
  "discount/add",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await DiscountServices.addDiscount(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const updateDiscount = createAsyncThunk(
  "discount/update",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await DiscountServices.updateDiscount(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const deleteDiscount = createAsyncThunk(
  "discount/delete",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.adminToken;
      return await DiscountServices.deleteDiscount(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

const DiscountSlice = createSlice({
  name: "discount",
  initialState: initialDiscountState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDiscounts.pending, (state) => {
        state.status = "loading";
        state.message = "Loading Discounts";
      })
      .addCase(getAllDiscounts.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Discounts Fetched Successfully";
        state.discounts = action.payload.result;
      })
      .addCase(getAllDiscounts.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while fetching ${action.payload}`;
        state.discounts = [];
      })
      .addCase(addDiscount.pending, (state) => {
        state.status = "loading";
        state.message = "Creating Discount";
      })
      .addCase(addDiscount.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Discount Created Successfully";
      })
      .addCase(addDiscount.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while creating ${action.payload}`;
        state.discounts = [];
      })
      .addCase(updateDiscount.pending, (state) => {
        state.status = "loading";
        state.message = "Upating Discount";
      })
      .addCase(updateDiscount.fulfilled, (state) => {
        state.status = "success";
        state.message = "Discount updated Successfully";
        state.discounts = {};
      })
      .addCase(updateDiscount.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while updating ${action.payload}`;
      })
      .addCase(deleteDiscount.pending, (state) => {
        state.status = "loading";
        state.message = "Deleting Discount";
      })
      .addCase(deleteDiscount.fulfilled, (state) => {
        state.status = "success";
        state.message = "Discount deleted Successfully";
      })
      .addCase(deleteDiscount.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while deleting ${action.payload}`;
      });
  },
});

export const DiscountActions = DiscountSlice.actions;

export default DiscountSlice.reducer;
