import Login from "./pages/Login";
import PrivateRoutes from "./PrivateRoutes";
import Layouts from "./components/UI/Layouts";
import Loader from "./components/common/Loader";
import AddBlog from "./components/blog/AddBlog";
import EditBlog from "./components/blog/EditBlog";
import { getAdmin } from "./store/slice/authSlice";
import EditCombo from "./components/combos/EditCombo";
import { useDispatch, useSelector } from "react-redux";
import React, { lazy, useEffect, Suspense } from "react";
import AddVariant from "./components/variant/AddVariant";
import EditVariant from "./components/variant/EditVariant";
import AddEmployee from "./components/employee/AddEmployee";
import EditEmployee from "./components/employee/EditEmployee";
import AddActivity from "./components/activity/AddActivity";
import EditActivity from "./components/activity/EditActivity";
import AddMetaDetail from "./components/metaDetail/AddMetaDetail";
import EditMetaDetail from "./components/metaDetail/EditMetaDetail";
import {
  getAllActivities,
  getAllMetaCombos,
} from "./store/slice/activitySlice";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import AddCombo from "./components/combos/AddCombo";

// lazy loading
const Blog = lazy(() => import("./pages/Blog"));
const Users = lazy(() => import("./pages/Users"));
const Combo2 = lazy(() => import("./pages/Combo2"));
const Banners = lazy(() => import("./pages/Banners"));
const Variant = lazy(() => import("./pages/Variant"));
const Gallery = lazy(() => import("./pages/Gallery"));
const EditUser = lazy(() => import("./pages/EditUser"));
const Bookings = lazy(() => import("./pages/Bookings"));
const Activity2 = lazy(() => import("./pages/Activity2"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const MetaDetail = lazy(() => import("./pages/MetaDetail"));
const EmployeeList = lazy(() => import("./pages/EmployeeList"));

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const token = localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
    if (token) {
      dispatch(getAdmin(token));
      dispatch(getAllActivities()).then(() => dispatch(getAllMetaCombos()));
    }
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div>
      <Layouts>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={isLoggedIn ? `/dashboard` : "/"}
              element={isLoggedIn ? <Dashboard /> : <Login />}
            />
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/employees">
                <Route index element={<EmployeeList />} />
                <Route path="add" element={<AddEmployee />} />
                <Route path=":employeeId" element={<EditEmployee />} />
              </Route>
              <Route path="/activity">
                <Route index element={<Activity2 />} />
                <Route path="add" element={<AddActivity />} />
                <Route path=":activityId" element={<EditActivity />} />
              </Route>
              <Route path="/variant">
                <Route index element={<Variant />} />
                <Route path="add" element={<AddVariant />} />
                <Route path=":variantId" element={<EditVariant />} />
              </Route>
              <Route path="/combos">
                <Route index element={<Combo2 />} />
                <Route path="add" element={<AddCombo />} />
                <Route path=":comboId" element={<EditCombo />} />
              </Route>
              <Route path="/users">
                <Route index element={<Users />} />
                <Route path=":userId" element={<EditUser />} />
              </Route>
              <Route path="/blog">
                <Route index element={<Blog />} />
                <Route path="add" element={<AddBlog />} />
                <Route path=":blogId" element={<EditBlog />} />
              </Route>
              <Route path="/metaDetail">
                <Route index element={<MetaDetail />} />
                <Route path="add" element={<AddMetaDetail />} />
                <Route path=":metaDetailId" element={<EditMetaDetail />} />
              </Route>
              <Route path="/banners" element={<Banners />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/bookings" element={<Bookings />} />
              <Route path="/contact" element={<ContactUs />} />
            </Route>
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Suspense>
      </Layouts>
    </div>
  );
}

export default App;
