export const navbarIconAnim = {
  hidden: {
    rotate: 90,
  },
  show: {
    rotate: 0,
  },
};

export const activitySlotAnim = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
  },
  exit: {
    y: 10,
    opacity: 0,
    delay: 1,
  },
};

export const navBarAnim = {
  hidden: {
    opacity: 0,
    width: "0px",
  },
  show: {
    opacity: 1,
    width: "225px",
    transition: { ease: "linear" },
  },
  exit: {
    opacity: 0,
    width: "0px",
    transition: { duration: 0.25 },
  },
};

export const navUlAnim = {
  show: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const naLiAnim = {
  show: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
