import axios from "axios";

const BASE_URL = process.env.REACT_APP_ADMIN_URL;
const COMBOS = "admin/private/api/combo/meta";

const getAllCombos = async (token) => {
  const resp = await axios.get(`${BASE_URL}${COMBOS}`, {
    headers: {
      Authorization: token,
    },
  });

  return resp.data;
};

const comboService = {
    getAllCombos,
  };
  
  export default comboService;