import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_ADMIN_URL;
const LOGIN_URL = "admin/private/api/auth/verify";
const GET_ALL_USERS = "admin/private/api/auth/";
const GET_ADMIN = "admin/private/api/auth/getAdmin";

const loginAdmin = async (data) => {
  const resp = await axios.post(`${BASE_URL}${LOGIN_URL}`, data);
  if (resp.data.result) {
    localStorage.setItem(
      "bf215bc5-252d-4e76-9b06-754f55268dd2",
      resp.data.result.accessToken
    );
  }
  if (resp.data.message === "Success") {
    toast.success("Logged In Successfully");
  } else {
    toast.error(resp.data.message);
  }
  return resp.data;
};

const getAllUsers = async (token) => {
  const resp = await axios.get(`${BASE_URL}${GET_ALL_USERS}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const getAdmin = async (token) => {
  const resp = await axios.get(`${BASE_URL}${GET_ADMIN}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp.data;
};

const authServices = {
  loginAdmin,
  getAllUsers,
  getAdmin,
};

export default authServices;
