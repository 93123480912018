import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import mealServices from "../service/mealService";

const initialMealState = {
  meals: [],
  status: "",
  message: "",
};

export const getAllMeals = createAsyncThunk(
  "meals/getAll",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await mealServices.getAllMeals(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

// export const getMealById = createAsyncThunk("meal/getById", async(data, thunkAPI) => {
//   try {
//     const token =
//         thunkAPI.getState().auth.adminToken ||
//         localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
//       return await mealServices.getMealById(data, token);
//   } catch (e) {
//     const msg =
//         (e.response && e.response.data && e.response.data.message) ||
//         e.message ||
//         e.toString();
//       return thunkAPI.rejectWithValue(msg);
//   }
// })

export const addMeal = createAsyncThunk("meal/add", async (data, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState().auth.adminToken ||
      localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
    return await mealServices.addMeal(data, token);
  } catch (e) {
    const msg =
      (e.response && e.response.data && e.response.data.message) ||
      e.message ||
      e.toString();
    return thunkAPI.rejectWithValue(msg);
  }
});

export const updateMeal = createAsyncThunk(
  "meal/update",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await mealServices.updateMeal(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

export const deleteMeal = createAsyncThunk(
  "meal/delete",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.adminToken;
      return await mealServices.deleteMeal(data, token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

const mealSlice = createSlice({
  name: "meals",
  initialState: initialMealState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMeals.pending, (state) => {
        state.status = "loading";
        state.message = "Loading Meals";
      })
      .addCase(getAllMeals.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Meals Fetched Successfully";
        state.meals = action.payload.result;
      })
      .addCase(getAllMeals.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while fetching ${action.payload}`;
        state.meals = [];
      })
      .addCase(addMeal.pending, (state) => {
        state.status = "loading";
        state.message = "Creating Meal";
      })
      .addCase(addMeal.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Meal Created Successfully";
      })
      .addCase(addMeal.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while creating ${action.payload}`;
        state.meals = [];
      })
      .addCase(updateMeal.pending, (state) => {
        state.status = "loading";
        state.message = "Upating Meal";
      })
      .addCase(updateMeal.fulfilled, (state) => {
        state.status = "success";
        state.message = "Meal updated Successfully";
        state.meal = {};
      })
      .addCase(updateMeal.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while updating ${action.payload}`;
      })
      .addCase(deleteMeal.pending, (state) => {
        state.status = "loading";
        state.message = "Deleting Meal";
      })
      .addCase(deleteMeal.fulfilled, (state) => {
        state.status = "success";
        state.message = "Meal deleted Successfully";
      })
      .addCase(deleteMeal.rejected, (state, action) => {
        state.status = "error";
        state.message = `Something went wrong while deleting ${action.payload}`;
      });
  },
});

export const mealActions = mealSlice.actions;

export default mealSlice.reducer;
