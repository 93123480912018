import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import comboService from "../service/comboService";

const initialComboState = {
  message: "",
  status: "",
  metaCombos: [],
};

export const getMetaCombos = createAsyncThunk(
  "combos/getAll",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState().auth.adminToken ||
        localStorage.getItem("bf215bc5-252d-4e76-9b06-754f55268dd2");
      return await comboService.getAllCombos(token);
    } catch (e) {
      const msg =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return thunkAPI.rejectWithValue(msg);
    }
  }
);

const comboSlice = createSlice({
  name: "combo",
  initialState: initialComboState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMetaCombos.pending, (state) => {
        state.status = "pending";
        state.message = "Loading Activities";
      })
      .addCase(getMetaCombos.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "Combos fetched successfully";
        state.metaCombos = action.payload.result;
      })
      .addCase(getMetaCombos.rejected, (state, action) => {
        state.status = "rejected";
        state.metaCombos = [];
        state.message = `Something went wrong while fetching combos ${action.payload.message}`;
      });
  },
});

export const comboAction = comboSlice.actions;

export default comboSlice.reducer;
