import banner from "../../assets/banner.svg";
import { ImCross } from "react-icons/im";
import { motion, LayoutGroup } from "framer-motion";

const ActivityDropBox = ({
  imageData,
  setImageData,
  handleRemoveImage,
  showUploadSection,
  input,
  className,
  bannerPage,
}) => {
  const handleDragEnter = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    e.preventDefault();
    const data = e.dataTransfer.files;
    setImageData(data);
  };
  const handleClick = () => {
    document.getElementById(input).click();
  };
  const handleChange = (e) => {
    const data = e.target.files;
    setImageData(data);
  };

  const handleRemove = (key, isUrl) => {
    handleRemoveImage(key, isUrl);
  };

  return (
    <>
      <motion.div className="flex p-6 gap-4 flex-wrap z-50">
        <LayoutGroup>
          {imageData.length > 0
            ? Array.from(imageData).map((f, index) => (
                <motion.div
                  key={index}
                  className="h-36 w-56 rounded-lg relative"
                  id={`imageViewer_${index}`}
                  layout
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ type: "spring" }}
                >
                  {bannerPage ? (
                    f?.image_url?.includes(".mp4") ||
                    f?.file?.name.includes(".mp4") ? (
                      <video
                        src={
                          f.image_url
                            ? f.image_url
                            : URL.createObjectURL(f.file)
                        }
                        className="object-cover h-full w-full rounded-lg"
                        loading="lazy"
                        controls
                      />
                    ) : (
                      <img
                        src={
                          f.image_url
                            ? f.image_url
                            : URL.createObjectURL(f.file)
                        }
                        alt=""
                        className="object-cover h-full w-full rounded-lg"
                        loading="lazy"
                      />
                    )
                  ) : (
                    <img
                      src={
                        f.image_url ? f.image_url : URL.createObjectURL(f.file)
                      }
                      alt=""
                      className="object-cover h-full w-full rounded-lg"
                      loading="lazy"
                    />
                  )}
                  <span
                    className="absolute -right-2 -top-2 rounded-full p-1 border bg-white cursor-pointer"
                    onClick={() => handleRemove(f.key, f.image_url)}
                  >
                    <ImCross className="text-gray-500 text-xs pointer-events-none" />
                  </span>
                </motion.div>
              ))
            : null}
        </LayoutGroup>
      </motion.div>
      {showUploadSection ? (
        <div
          className={`bg-white w-full rounded-lg z-10 ${className}`}
          id="drop-region"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            id={input}
            accept={
              banner
                ? "video/mp4,video/x-m4v,video/,image/png,image/jpeg,image/jpg,image/webp"
                : ".png, .jpg, .jpeg, .webp"
            }
            className="hidden"
            multiple
            onChange={handleChange}
          />

          <div id="image-preview " onClick={handleClick} className="py-6">
            <div className="flex flex-col items-center justify-center gap-4">
              <img src={banner} alt="" />
              <p>Drag and drop files here or click to upload files jpeg/.png</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ActivityDropBox;
