import { CiCalendar } from "react-icons/ci";
import { FaBloggerB } from "react-icons/fa";
import { VscPackage } from "react-icons/vsc";
import { RiContactsLine } from "react-icons/ri";
import { BiUser, BiDetail } from "react-icons/bi";
import { IoDocumentOutline } from "react-icons/io5";
import { AiOutlineNodeIndex } from "react-icons/ai";
import { MdOutlineDashboardCustomize, MdImageSearch } from "react-icons/md";

export const activePage = [
  {
    id: 0,
    title: "Dashboard",
    icon: <MdOutlineDashboardCustomize />,
    url: "/dashboard",
  },
  {
    id: 1,
    title: "Employee",
    icon: <BiUser />,
    url: "/employees",
  },
  {
    id: 2,
    title: "Booking",
    icon: <CiCalendar />,
    url: "/bookings",
  },
  {
    id: 3,
    title: "Banners",
    icon: <IoDocumentOutline />,
    url: "/banners",
  },
  {
    id: 4,
    title: "Activtiy/Ride",
    icon: <AiOutlineNodeIndex />,
    url: "/activity",
  },
  {
    id: 5,
    title: "Combos",
    icon: <VscPackage />,
    url: "/combos",
  },
  // {
  //   id: 6,
  //   title: "Menu",
  //   icon: <IoFastFoodOutline />,
  //   url: "/menu",
  // },
  // {
  //   id: 7,
  //   title: "Discounts",
  //   icon: <BsTag />,
  //   url: "/discounts",
  // },
  // {
  //   id: 8,
  //   title: "Payments",
  //   icon: <MdOutlinePayments />,
  //   url: "/payments",
  // },
  // {
  //   id: 9,
  //   title: "Users",
  //   icon: <AiOutlineUserAdd />,
  //   url: "/users",
  // },
  // {
  //   id: 10,
  //   title: "Activity Slots",
  //   icon: <HiOutlineInbox />,
  //   url: "/slots",
  // },
  {
    id: 11,
    title: "Variant",
    icon: <AiOutlineNodeIndex />,
    url: "/variant",
  },
  {
    id: 12,
    title: "Gallery Images",
    icon: <MdImageSearch />,
    url: "/gallery",
  },
  {
    id: 13,
    title: "Contact Us",
    icon: <RiContactsLine />,
    url: "/contact",
  },
  {
    id: 14,
    title: "Blog",
    icon: <FaBloggerB />,
    url: "/blog",
  },
  {
    id: 15,
    title: "Meta Detail",
    icon: <BiDetail />,
    url: "/metaDetail",
  },
];
