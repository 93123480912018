import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import mealReducer from "./slice/mealSlice";
import paymentReducer from "./slice/paymentSlice";
import activityReducer from "./slice/activitySlice";
import discountReducer from "./slice/discountSlice";
import comboReducer from "./slice/comboSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    meals: mealReducer,
    payment: paymentReducer,
    activity: activityReducer,
    discount: discountReducer,
    combos: comboReducer,
  },
});

export default store;
