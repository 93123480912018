import React from "react";
import { useState } from "react";
import Button from "../components/common/Button";
import { useFormik } from "formik";
import { schemas } from "../schema/loginSchema";
import BrandLogo from "../assets/images/logoColor.webp";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { loginAdmin } from "../store/slice/authSlice";
import {
  getAllActivities,
  getAllMetaCombos,
} from "../store/slice/activitySlice";

const Login = () => {
  let inputStyle = "border rounded p-2.5 px-4 outline-none border-none";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: schemas.loginSchema,
      onSubmit: async () => {
        const data = {
          email: values.email,
          password: values.password,
        };
        dispatch(loginAdmin(data)).then((res) => {
          if (res.payload.result.adminExisted) {
            dispatch(getAllActivities()).then(() =>
              dispatch(getAllMetaCombos()).then(() => navigate("/dashboard"))
            );
          }
        });
      },
    });
  function handleOnClick() {
    setVisible(!visible);
  }
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 z-50">
      <div className="relative bg-gray-400 bg-[url('assets/images/modal-bg.png')] bg-no-repeat bg-cover bg-center h-screen">
        <div className="p-6">
          <img src={BrandLogo} alt="Brand Logo" />
        </div>
        <div className="flex flex-col min-w-[250px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <h2 className="text-2xl font-semibold pb-4">Login</h2>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <fieldset className="flex flex-col gap-2">
              <input
                placeholder="Enter your email"
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.email && touched.email
                    ? `${inputStyle} border-primary`
                    : inputStyle
                }
              />
              <div className="flex bg-[#ECEFF2] rounded items-center justify-between pr-2">
                <input
                  placeholder="Password"
                  type={visible ? "text" : "password"}
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password
                      ? `${inputStyle} border-primary`
                      : inputStyle
                  }
                />
                {visible ? (
                  <AiFillEye
                    onClick={handleOnClick}
                    className="cursor-pointer"
                    size={20}
                  />
                ) : (
                  <AiFillEyeInvisible
                    onClick={handleOnClick}
                    className="cursor-pointer"
                    size={20}
                  />
                )}
              </div>
            </fieldset>
            <Button
              type="submit"
              text="Continue"
              className="px-24 py-2 mt-5 text-white"
            />
          </form>
          <Link>
            <p className="text-center text-sm text-primary mt-4 hover:underline">
              Need help ?
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
