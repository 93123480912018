import axios from "axios";
import Button from "../common/Button";
import { Switch } from "@mui/material";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
// import UploadImage from "../common/UploadImage";
import { useDispatch, useSelector } from "react-redux";
import ActivityDropBox from "../common/ActivityDropBox";
import { useLocation, useNavigate } from "react-router-dom";
// import placeholder from "../../assets/images/placeholder.png";
import { getAllActivities } from "../../store/slice/activitySlice";
// Rich text Editor
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AddCombo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [multiImage, setMultiImage] = useState([]);
  const token = useSelector((state) => state.auth.adminToken);
  const activities = useSelector((state) => state.activity.activities);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  let [briefDescription, setBriefDescription] = useState("<div></div>");
  const [briefDescriptionHTML, setBriefDescriptionHTML] = useState("");

  const handleContentStateChange = (briefDescription) => {
    setBriefDescription(draftToHtml(briefDescription));
    setBriefDescriptionHTML(draftToHtml(briefDescription));
  };
  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const [fields, setFields] = useState({
    name: "",
    price: "",
    info: "",
    rating: "",
    status: true,
    imageUrl: "",
    location: "",
    sequence: "",
    duration: "",
    languages: "",
    noOfPerson: "",
    youtubeUrl: "",
    slotPerDay: "",
    activities: [],
    description: "",
    recommended: [],
    activityType: "Solo",
    videoThumbnail: "",
    multipleImages: [],
  });

  const handleCancel = () => {
    navigate("/combos");
  };

  useEffect(() => {
    localStorage.setItem("location", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // handle field events & upload single file
  const handleOnChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  // const handleThumbnailChange = async (e) => {
  //   const data = new FormData();
  //   data.append("media", e.target.files[0]);
  //   const resp = await axios.post(
  //     `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/package/thumbnail`,
  //     data,
  //     { headers: { Authorization: token } }
  //   );
  //   if (resp.data.message === "Limit exceeded") {
  //     return toast.warn("File size should not be more than 100kb");
  //   }
  //   if (resp.data.message === "Success") {
  //     setFields((prevFields) => ({
  //       ...prevFields,
  //       videoThumbnail: resp.data.url,
  //     }));
  //   }
  // };
  // const handleVideoChange = async (e) => {
  //   const data = new FormData();
  //   data.append("media", e.target.files[0]);
  //   const resp = await toast.promise(
  //     axios.post(
  //       `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/package/single`,
  //       data,
  //       { headers: { Authorization: token } }
  //     ),
  //     {
  //       pending: "Uploading activity...",
  //       success: "Uploaded Successfully!",
  //       error: "Something went wrong",
  //     }
  //   );

  //   if (resp.data.message === "Success") {
  //     setFields((prevFields) => ({
  //       ...prevFields,
  //       imageUrl: resp.data.url,
  //     }));
  //   }
  // };
  const uploadImageCallBack = async (file) => {
    const data = new FormData();
    data.append("media", file);
    const resp = await axios.post(
      `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/package/single`,
      data,
      { headers: { Authorization: token } }
    );
    if (resp.data.message === "Success") {
      const imageObj = {
        file: file,
        localSrc: resp.data.url,
      };
      return new Promise((resolve, reject) => {
        resolve({ data: { link: imageObj.localSrc } });
      });
    }
  };

  // handle multiple images
  const handleMultipleImageChange = (data) => {
    if (Object.keys(data).length === 1)
      setMultiImage((prevImage) => {
        return [
          ...prevImage,
          { key: Math.floor(Math.random() * 100000), file: data[0] },
        ];
      });
    else {
      Array.from(data).forEach((file) => {
        setMultiImage((prevImage) => {
          return fields.multipleImages.length + prevImage.length < 4
            ? [
                ...prevImage,
                { key: Math.floor(Math.random() * 100000), file: file },
              ]
            : [...prevImage];
        });
      });
    }
  };
  // remove previous images
  const handleRemoveImage = (key, isUrl) => {
    const searchArray = isUrl ? fields.multipleImages : multiImage;
    const filteredImages = searchArray.filter((item) => item.key !== key);
    !isUrl
      ? setMultiImage(filteredImages)
      : setFields((prevFields) => {
          return {
            ...prevFields,
            multipleImages: filteredImages,
          };
        });
  };
  // upload multiple images
  const uploadMultiImageHandler = async (imageField) => {
    const data = new FormData();
    if (imageField === "activityImage") {
      multiImage.forEach((img) => {
        data.append("images", img.file);
      });
    }
    const resp = await toast.promise(
      axios.post(
        `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/package/multiple`,
        data,
        { headers: { Authorization: token } }
      ),
      {
        pending: "Uploading images...",
        success: "Images uploaded successfully",
        error: "Something went wrong",
      }
    );
    if (resp.data.message === "Success") {
      if (imageField === "activityImage") {
        setFields((prevFields) => {
          return {
            ...prevFields,
            multipleImages: [...prevFields.multipleImages, ...resp.data.urls],
          };
        });
        setMultiImage([]);
      }
    }
  };
  // save data to database
  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      price,
      info,
      rating,
      status,
      imageUrl,
      location,
      sequence,
      duration,
      languages,
      noOfPerson,
      youtubeUrl,
      activities,
      slotPerDay,
      recommended,
      activityType,
      videoThumbnail,
      multipleImages,
    } = fields;
    const data = {
      name,
      price,
      info,
      rating,
      status,
      imageUrl,
      location,
      sequence,
      duration,
      languages,
      noOfPerson,
      slotPerDay,
      youtubeUrl,
      activities,
      description: briefDescription || briefDescriptionHTML,
      recommended,
      activityType,
      videoThumbnail,
      multipleImages,
    };
    if (
      data?.description === null ||
      data?.description.length === 1 ||
      data?.description.length === 0 ||
      data?.description === "<p></p>\n" ||
      data?.description === undefined
    )
      return toast.warn("Please provide a description");
    if (data?.youtubeUrl === "") return toast.warn("Please provide Video URL");
    // if (data?.videoThumbnail === "")
    //   return toast.warn("Please provide video thumbnail");
    if (data?.multipleImages.length !== 4)
      return toast.warn("Please provide 4 images");

    const resp = await axios.post(
      `${process.env.REACT_APP_ADMIN_URL}admin/private/v2/api/package/`,
      data,
      { headers: { Authorization: token } }
    );
    if (resp.data.message === "Created") {
      dispatch(getAllActivities())
        .then(() => navigate("/combos"))
        .then(() => toast.success("Added Successfully", { autoClose: 1000 }));
    } else {
      toast.error("Something went wrong", { autoClose: 1500 });
    }
  };

  const handleEditorChange = (e) => {
    setBriefDescription("");
    const finalString = briefDescription + e.target.value;
    if (briefDescription.length === 0) setBriefDescriptionHTML(finalString);
    else setBriefDescriptionHTML(e.target.value);
    const blocksFromHtml = htmlToDraft(
      briefDescription.length > 0 ? e.target.value : finalString
    );
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  };

  return (
    <div className="px-8 py-4">
      <h1 className="text-2xl text-primary">Add Combo</h1>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="grid grid-cols-3 pt-4 gap-y-4 gap-x-10">
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="name">
              Name <small>(maximum 50 characters)</small>:
            </label>
            <input
              type="text"
              name="name"
              id="name"
              pattern="^[^-\s][a-zA-Z \s]*$"
              maxLength={50}
              onChange={handleOnChange}
              value={fields.name}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="price">
              Price<small>*</small>
            </label>
            <input
              min={1}
              type="number"
              name="price"
              id="price"
              onChange={handleOnChange}
              value={fields?.price}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="rating">
              Rating<small>*</small>
            </label>
            <input
              type="text"
              name="rating"
              id="rating"
              pattern="^[^-\s][0-9 \s]*$"
              maxLength={3}
              onChange={handleOnChange}
              value={fields?.rating}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div>
            <label className="text-gray-500 block mb-1">Status</label>
            <div className="flex gap-1 items-center bg-white border border-gray-200 px-2 rounded-sm">
              <p className={!fields.status ? "text-red-500" : "text-gray-500"}>
                Inactive
              </p>
              <Switch
                checked={fields.status}
                color="warning"
                onChange={handleOnChange}
                inputProps={{ "aria-label": "controlled" }}
                name="status"
              />
              <p className={fields.status ? "text-green-500" : "text-gray-500"}>
                Active
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="location">
              Location:
            </label>
            <input
              type="text"
              name="location"
              id="location"
              pattern="^[A-Za-z\s]*$"
              onChange={handleOnChange}
              value={fields.location}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="sequence">
              Sequence<small>*</small>
            </label>
            <input
              min={1}
              type="number"
              name="sequence"
              id="sequence"
              onChange={handleOnChange}
              value={fields?.sequence}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="duration">
              Duration <small className="text-xs">(in mins)</small> :
            </label>
            <input
              type="number"
              min={1}
              max={999}
              name="duration"
              id="duration"
              onChange={handleOnChange}
              value={fields.duration}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="languages">
              Languages <small className="text-xs">(Separate by comma)</small>:
            </label>
            <input
              type="text"
              name="languages"
              id="languages"
              pattern="^[^-\s][a-zA-Z,\s]*$"
              onChange={handleOnChange}
              value={fields.languages}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="noOfPerson">
              Number of Person<small>*</small>
            </label>
            <input
              min={1}
              type="number"
              name="noOfPerson"
              id="noOfPerson"
              onChange={handleOnChange}
              value={fields?.noOfPerson}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="slotPerDay">
              Slots Per Day<small>*</small>
            </label>
            <input
              min={1}
              type="number"
              name="slotPerDay"
              id="slotPerDay"
              onChange={handleOnChange}
              value={fields?.slotPerDay}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="activityType">
              Activity Type:
            </label>
            <select
              name="activityType"
              id="activityType"
              onChange={handleOnChange}
              className="px-3
              py-2
              text-base
              font-normal"
              required
            >
              <option value="none" disabled hidden>
                choose an option
              </option>
              <option value="solo">Solo</option>
              <option value="duo">Duo</option>
              <option value="group">Group</option>
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="activities">
              Activities Included<small>*</small>
            </label>
            <Select
              labelId="demo-multiple-name-label"
              id="activities"
              name="activities"
              multiple
              value={fields.activities}
              onChange={handleOnChange}
              className="bg-white"
              required
            >
              {activities?.map((act) => (
                <MenuItem key={act._id} value={act._id}>
                  {act.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-gray-500" htmlFor="recommended">
              Recommended:
            </label>
            <Select
              labelId="demo-multiple-name-label"
              id="recommended"
              name="recommended"
              multiple
              value={fields.recommended}
              onChange={handleOnChange}
              className="bg-white"
              required
            >
              {activities?.map((act) => (
                <MenuItem key={act._id} value={act._id}>
                  {act.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <fieldset className="w-full mt-5">
          <label htmlFor="info" className="block mb-1 text-gray-500">
            Info:
          </label>
          <textarea
            id="info"
            name="info"
            pattern="^[A-Za-z\s]*$"
            value={fields.info}
            onChange={handleOnChange}
            rows={5}
            required
            className="border p-2 w-full outline-none rounded"
          ></textarea>
        </fieldset>
        <div className="w-full mt-5">
          <label htmlFor="description" className="block mb-1 text-gray-500">
            Description:
          </label>
          <Editor
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                previewImage: true,
                alt: { present: true, mandatory: false },
                inputAccept:
                  "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              },
            }}
            editorState={editorState}
            toolbarClassName="editor-toolbar"
            wrapperClassName="editor-wrapper"
            editorClassName="editor"
            onEditorStateChange={handleEditorStateChange}
            onContentStateChange={handleContentStateChange}
          />
        </div>
        <div className="mt-5">
          <label>HTML:</label>
          <textarea
            type="text"
            id="htmlChange"
            name="htmlChange"
            value={briefDescriptionHTML}
            onChange={handleEditorChange}
            className="w-full p-4 my-2 outline-none border-none bg-white min-h-[380px] over"
          />
        </div>
        <div className="flex gap-5 my-5">
          <div className="flex flex-col gap-1 w-full">
            <label className="text-gray-500" htmlFor="location">
              Youtube URL:
            </label>
            <input
              type="text"
              name="youtubeUrl"
              id="youtubeUrl"
              onChange={handleOnChange}
              value={fields.youtubeUrl}
              required
              className="border p-2 w-full outline-none rounded"
            />
          </div>
          {/* <div className="flex gap-4 w-1/2">
            <div className="w-40 rounded-lg mt-8">
              {fields?.imageUrl ? (
                <video controls src={fields.imageUrl} alt="activity" />
              ) : (
                <img src={placeholder} alt={"add activity"} />
              )}
            </div>
            <UploadImage
              onChange={handleVideoChange}
              htmlFor="video"
              title="Activity Video"
              onboxText="video"
            />
          </div>
          <div className="flex gap-4 w-1/2">
            <div className="w-40 rounded-lg mt-8">
              {fields?.videoThumbnail ? (
                <img src={fields?.videoThumbnail} alt="activity" />
              ) : (
                <img src={placeholder} alt={"add activity"} />
              )}
            </div>

            <UploadImage
              onChange={handleThumbnailChange}
              htmlFor="image"
              title="Activity Thumbnail"
            />
          </div> */}
        </div>
        <div className="w-full">
          <>
            <h2 className="text-gray-500 mb-1">
              Add Multiple Images (4) <small>(banner images)</small> :
            </h2>
            <ActivityDropBox
              imageData={[...fields.multipleImages, ...multiImage]}
              setImageData={handleMultipleImageChange}
              handleRemoveImage={handleRemoveImage}
              className="min-h-[100px]"
              showUploadSection={
                fields.multipleImages.length + multiImage.length < 4
              }
              input="activityImages"
            />
            {multiImage.length > 0 ? (
              <Button
                text="Upload"
                type="button"
                handler={() => uploadMultiImageHandler("activityImage")}
                className={`block mt-2 text-white px-4 py-2 ml-auto`}
              />
            ) : null}
          </>
        </div>
        <div className="flex gap-4 justify-end">
          <Button
            text="Cancel"
            type="submit"
            className={`mt-2 text-white px-10 py-2 bg-amber-500 border-amber-500`}
            handler={handleCancel}
          />
          <Button
            text="Submit"
            type="submit"
            disabled={
              fields.multipleImages.length === 4 &&
              fields.youtubeUrl.length <= 0
            }
            className={`mt-2 text-white px-10 py-2 ${
              fields.youtubeUrl.length > 0 &&
              fields.multipleImages.length === 4 &&
              (fields.description !== null || fields.description !== undefined)
                ? "cursor-pointer"
                : "cursor-not-allowed"
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default AddCombo;
